import styled from 'styled-components';
export const BoxInterestAndFee = styled.div`
  .ant-table-header.ant-table-sticky-holder {
    top: 70px !important;
  }
  /* background-color: #12161c;
  padding: 24px;
  .box-content {
    background-color: #1a1f25;
    padding: 16px 24px;
  } */
  .fix-width-tab {
    width: 150px !important;
  }
`;
export const H2 = styled.div`
  padding-left: 24px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #f2f2f2;
  padding-bottom: 25px;
`;
export const TabsTable = styled.div`
  border-bottom: 24px solid #12161c;
  position: sticky;
  top: -1px;
  font-variant: JIS04;
  font-size: 3em;
  z-index: 10;
  transition: 0.2s ease-out;
  background: #1a1f25;
  padding-left: 24px;
  .box-content {
    display: flex;
    width: 170px;
    justify-content: space-between;
    .item {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #d9d9d9;
      padding: 12px 0px 12px 0px;
      border-bottom: 2px solid #1a1f25 !important;
    }
    .active-tabs {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #f2c015;
      border-bottom: 2px solid #f2c015 !important;
    }
  }
`;
export const FormSearch = styled.div`
  .ant-select {
    min-width: 197px !important;
  }
  margin-left: 3px;
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    width: 340px;
    height: 32px;
  }
  .ant-picker-panel-container {
    font-size: 13px !important;
  }
  .ant-select-selector {
    width: 247px;
  }
  .ant-picker {
    height: 32px;
    border-radius: 4px;
  }
  justify-content: start;
  .ant-picker-datetime-panel {
    font-size: 13px;
  }
  .box-formsearch {
    display: flex;
    justify-content: start;
  }
`;
