import React, { useContext, useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../Disbursement.styles';
import Avatar from '@app/assets/icons/avatar.svg';
import { ReactComponent as IconUploadPDF } from '@app/assets/icons/icon-upload-pdf.svg';

import { ColumnsType } from 'antd/es/table';
import { FormSearchDisbursement } from './FormSearchLoan';
import { getListDisbursementApi } from '../Disbursement.api';
import { DisbursementContext } from '../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { DataResponseDisbursement, DataTable } from '../interface';
import { updateFiltersDisbursement, updateStateModalDisbursement } from '../Action';
import { formatNumberWithCommas } from '@app/utils/utils';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';
import ModalDetail from './ModalDetail';
import { useNavigate } from 'react-router-dom';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import ModalCreateProof from './ModalCreateProof';

export default function TableDisbursementedTab() {
  const { stateDisbursement, dispatchDisbursement } = useContext(DisbursementContext);
  const [checkLoading, setcheckLoading] = useState(false);
  const [dataCode, setdataCode] = useState<DataTable>();
  const navigate = useNavigate();

  const [data, setdata] = useState<DataResponseDisbursement>();
  const callApiListDisbursement = async () => {
    setcheckLoading(true);
    await getListDisbursementApi(queryStringParams(stateDisbursement?.filters))
      .then((res) => {
        setdata(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setcheckLoading(false);
      });
  };

  function showModalDetail(value: DataTable) {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalDetail: true }));
    setdataCode(value);
  }

  function moveDetailLoan(value: DataTable) {
    navigate(`/customers/loan/${value?.loanCode}`);
  }

  function showModalCreateProof(value: DataTable) {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalCreateProof: true }));
    setdataCode(value);
  }

  useEffect(() => {
    callApiListDisbursement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateDisbursement?.filters]);

  const columns: ColumnsType<any> = [
    {
      width: '140px',
      title: 'Mã giải ngân',
      key: 'code',
      dataIndex: 'code',
      render: (value: string) => <S.ColumnCodeStyle>{value ? value : ''}</S.ColumnCodeStyle>,
    },
    {
      width: '150px',
      title: 'ID khoản vay',
      key: 'loanCode',
      render: (value: DataTable) => {
        return (
          <S.ColumnLoanCodeStyle>
            <a className="margin-left" onClick={() => moveDetailLoan(value)}>
              {value ? value?.loanCode : ''}
            </a>
          </S.ColumnLoanCodeStyle>
        );
      },
    },
    {
      width: '131px',
      title: 'Tên khách hàng',
      key: 'customerName',
      dataIndex: 'customerName',

      render: (value: string) => {
        return <UserNameAvatarColumn avatar={Avatar} name={value ? value : ''}></UserNameAvatarColumn>;
      },
    },
    {
      align: 'right',
      width: '150px',
      title: 'Số tiền',

      key: 'amount',
      dataIndex: 'amount',
      render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
    },
    {
      width: '121px',
      title: 'Đối tác liên kết',
      key: 'partner',
      dataIndex: 'partner',
    },
    {
      width: '150px',
      title: 'Loại sản phẩm',
      key: 'loanProductCode',
      dataIndex: 'loanProductCode',
    },
    {
      width: '150px',
      title: 'Nguồn giải ngân',
      key: 'fund',
      dataIndex: 'fund',
    },
    {
      width: '200px',
      title: 'TK nhận giải ngân',
      key: 'accountReceiveMoney',
      dataIndex: 'accountReceiveMoney',
      render: (value: string) => <div>{value ? value : '-'}</div>,
    },
    {
      width: '150px',
      title: 'Ngày giải ngân',
      sorter: (a: any, b: any) => a.age - b.age,

      key: 'transDate',
      dataIndex: 'transDate',
      render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
    },

    {
      width: '140px',
      title: 'Người thực hiện',
      key: 'executor',
      dataIndex: 'executor',
      render: (value: string) => <div>{value ? value : '-'}</div>,
    },
    {
      width: '220px',
      title: 'Chứng từ',
      key: 'proofKey',
      render: (value: DataTable) => (
        <div className="d-flex">
          {value?.proofKey ? (
            <a className="d-flex" onClick={() => showModalDetail(value)}>
              <IconUploadPDF className="style-icon-pdf margin-top-5 "></IconUploadPDF>
              <Tooltip placement="top" title={<span>{value?.proofKey && value?.proofKey ? value?.proofKey : ''}</span>}>
                <a className="style-text-pdf">{value?.proofKey}</a>
              </Tooltip>
            </a>
          ) : (
            <BtnCreateStyle onClick={() => showModalCreateProof(value)} label={'Chứng từ'}></BtnCreateStyle>
          )}
        </div>
      ),
    },
  ];

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxDisbursement>
            <FormSearchDisbursement></FormSearchDisbursement>
            <Table
              className="padding-table"
              scroll={{ x: 2300 }}
              rowSelection={{ type: 'checkbox' }}
              dataSource={data && data?.data}
              sticky={true}
              columns={columns}
              loading={checkLoading}
              rowKey="id"
              pagination={{
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number, pageSize: number) => {
                  dispatchDisbursement(updateFiltersDisbursement({ ...stateDisbursement?.filters, pageIndex: page, pageSize: pageSize }));
                },
              }}
            />
          </S.BoxDisbursement>
        </div>
        {dataCode && <ModalDetail callApiListDisbursement={callApiListDisbursement} data={dataCode && dataCode}></ModalDetail>}
        {dataCode && <ModalCreateProof callApiListDisbursement={callApiListDisbursement} data={dataCode && dataCode}></ModalCreateProof>}
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
