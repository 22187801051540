import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { DataResponseApiPolicyList, paramsApiCreatePolicy, paramsApiEditPolicy, paramsUpdateStatusPolicyApiType, responseApiCreatePolicy } from './interface';
const httpApi = initAxios();
interface LoanRequestConfig extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfig: LoanRequestConfig = {
  secured: true,
};

// const TokenConfigTextPlain: CreateDebtConfig = {
//   secured: true,
//   ContentType: 'text/plain',
// };

// const httpApi = initAxios();
export const getPolicyApi = (params: string | undefined): Promise<DataResponseApiPolicyList> => httpApi.get(`/loans/v1/policy?${params}`, { ...TokenConfig });

export const CreatePolicyApi = (params: paramsApiCreatePolicy): Promise<responseApiCreatePolicy> => httpApi.post(`loans/v1/policy`, params, { ...TokenConfig });

export const EditPolicyApi = (code: string, params: paramsApiEditPolicy): Promise<any> => httpApi.put(`loans/v1/policy/${code}`, params, { ...TokenConfig });

export const changeStatusPolicyApi = (code: string, params: paramsUpdateStatusPolicyApiType): Promise<any> => httpApi.put(`/loans/v1/Policys/${code}/status?`, params, { ...TokenConfig });
