import { ColumnsType } from 'antd/es/table';
import { DataTypeTableLoan } from '../interface';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import * as S from '../Loan.styles';
import { formatNumberWithCommas } from '@app/utils/utils';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import { loanPaymentLevelType } from '@app/interfaces/interfaces';
import { CreditScoring } from '@app/components/common/CreditScoring/CreditScoring';

function DetailLoan(record: any) {
  location.href = `loan/${record.code}`;
}

export const columns: ColumnsType<DataTypeTableLoan> = [
  {
    width: '230px',
    title: 'ID khoản vay',
    key: 'code',
    render: (record) => (
      <a onClick={() => DetailLoan(record)} style={{ color: '#F2C015' }}>
        {record.code ? record.code : ''}
      </a>
    ),
  },
  {
    width: '204px',
    title: 'Tên khách hàng',
    key: 'user',
    dataIndex: ['user', 'fullName'],
    render: (value: string) => {
      return <UserNameAvatarColumn name={value ? value : ''}></UserNameAvatarColumn>;
    },
  },
  {
    width: '130px',
    title: 'Điểm Tín dụng KH',
    key: 'userScore',
    dataIndex: 'userScore',
    render: (value: any) => {
      return <div>{value ? <CreditScoring score={value && value} /> : ''}</div>;
    },
  },
  {
    width: '130px',
    title: 'Điểm Tín dụng ĐH',
    key: 'orderScore',
    dataIndex: 'orderScore',
    render: (value: any) => {
      return <div>{value ? <CreditScoring score={value && value} /> : ''}</div>;
    },
  },
  {
    width: '150px',
    title: 'Số tiền vay',
    key: 'amount',
    dataIndex: 'amount',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
    },
  },
  {
    width: '150px',
    title: 'Lãi suất',
    key: 'interestRate',
    dataIndex: 'interestRate',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) + '%' : ''}</div>;
    },
  },
  {
    width: '155px',
    title: 'Phí lũy kế',
    key: 'accruedFeeAmount',
    dataIndex: 'accruedFeeAmount',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
    },
  },
  {
    width: '155px',
    title: 'Phí hoàn (Sau GN)',
    key: 'refundFeeAmount',
    dataIndex: 'refundFeeAmount',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
    },
  },
  {
    width: '155px',
    title: 'Phí đã trả ',
    key: 'feePaidAmount',
    dataIndex: 'feePaidAmount',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
    },
  },
  {
    width: '155px',
    title: 'Lãi lũy kế',
    key: 'accruedInterestAmount',
    dataIndex: 'accruedInterestAmount',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
    },
  },
  {
    width: '155px',
    title: 'Lãi hoàn(Sau GN)',
    key: 'refundInterestAmount',
    dataIndex: 'refundInterestAmount',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
    },
  },
  {
    width: '156px',
    title: 'Lãi đã trả',
    key: 'interestPaidAmount',
    dataIndex: 'interestPaidAmount',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
    },
  },
  {
    width: '204px',
    title: 'Gốc đã giải ngân',
    key: 'disbursedAmount',
    dataIndex: 'disbursedAmount',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
    },
  },
  {
    width: '155px',
    title: 'Gốc hoàn(Sau GN)',
    key: 'refundPrincipalAmount',
    dataIndex: 'refundPrincipalAmount',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
    },
  },
  {
    width: '205px',
    title: 'Gốc đã thanh toán',
    key: 'principalPaidAmount',
    dataIndex: 'principalPaidAmount',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
    },
  },
  {
    width: '250px',
    title: 'Tổng tiền cần thanh toán',
    key: 'totalAmountPay',
    dataIndex: 'totalAmountPay',
    align: 'right',
    render: (value: number) => {
      return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
    },
  },
  {
    width: '140px',
    title: 'REF ID',
    key: 'referenceId',
    dataIndex: 'referenceId',
    render: (value: string) => <a style={{ color: '#F2C015' }}>{value ? value : ''}</a>,
  },
  {
    width: '150px',
    title: 'Đối tác liên kết',
    key: 'partnerName',
    dataIndex: 'partnerName',
  },
  {
    width: '120px',
    title: 'Nguồn giải ngân',
    key: 'fundName',
    dataIndex: 'fundName',
  },
  {
    width: '211px',
    title: 'Ngày gửi request',
    key: 'requestDate',
    dataIndex: 'requestDate',
    sorter: (a: any, b: any) => a.age - b.age,
    render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
  },
  {
    width: '211px',
    title: 'Ngày duyệt',
    key: 'approvalDate',
    dataIndex: 'approvalDate',
    sorter: (a: any, b: any) => a.age - b.age,
    render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
  },
  {
    width: '211px',
    title: 'Ngày giải ngân',
    key: 'disbursementDate',
    dataIndex: 'disbursementDate',
    sorter: (a: any, b: any) => a.age - b.age,
    render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
  },

  {
    width: '226px',
    title: 'Ngày tất toán',
    key: 'completeDate',
    dataIndex: 'completeDate',
    sorter: (a: any, b: any) => a.age - b.age,
    render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
  },
  {
    width: '180px',
    title: 'Trạng thái TT',
    key: 'loanPaymentLevel',
    dataIndex: 'loanPaymentLevel',

    render: (value: loanPaymentLevelType) => (
      <div>
        {value && value == 'LEVEL_0' ? '' : '' || (value && value == 'LEVEL_1') ? 'Miễn phí' : '' || (value && value == 'LEVEL_2') ? 'Tính phí' : '' || (value && value == 'LEVEL_3') ? 'Thu hồi' : ''}
      </div>
    ),
  },

  {
    width: '226px',
    title: 'Số ngày quá hạn',
    key: 'numberDayOverdue',
    dataIndex: 'numberDayOverdue',
    sorter: (a: any, b: any) => a.age - b.age,

    render: (value: number) => <div>{value ? value + ' ngày' : ''}</div>,
  },
  {
    width: '200px',
    title: 'Người tạo',
    key: 'originationChannel',

    render: (value: DataTypeTableLoan) => <div>{value?.originationChannel ? value?.originationChannel : '-'}</div>,
  },
  {
    width: '165px',
    title: 'Trạng thái',
    key: 'status',
    fixed: 'right',
    dataIndex: 'status',
    render: (value: any) => {
      return <StatusesText status={value} context="LOAN"></StatusesText>;
    },
  },

  {
    width: '140px',
    title: 'Hành động',
    fixed: 'right',
    key: 'customer_action',
    render: (record) => {
      return <S.styleTextAction onClick={() => DetailLoan(record)}>Xem</S.styleTextAction>;
    },
  },
];
