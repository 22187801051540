import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { ColumnsType } from 'antd/lib/table';
import { formatNumberWithCommas } from '@app/utils/utils';
import { DataTablePolicy } from '../interface';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { updateFiltersPolicy } from '../Action';
import { PolicyContext } from '../Context';
import { FormSearchPolicy } from './FormSearchPolicy';
import { getPolicyApi } from '../Policy.api';
import { DataResponseApiPolicyList } from '../interface';
import ModalEditPolicy from './ModalEditPolicy';
import { StatusPolicy } from '@app/interfaces/interfaces';
import { updateModalsPolicy } from '../Action';
import { ModalCreatePolicy } from '../Policy.style';
import ModalDetailPolicy from './ModalDetailPolicy';

export default function TablePolicy() {
  const [checkLoading, setcheckLoading] = useState(false);
  const [dataPolicy, setDataPolicy] = useState<DataResponseApiPolicyList>();
  const [saveDataRecord, setsaveDataRecord] = useState<DataTablePolicy>();
  const { statePolicy, dispatchPolicy } = useContext(PolicyContext);

  const callApiListPolicy = async () => {
    setcheckLoading(true);
    await getPolicyApi(queryStringParams(statePolicy?.filtersPolicy))
      .then((res) => {
        setDataPolicy(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDataPolicy(undefined);

        setcheckLoading(false);
      });
  };

  // function handleConfirm() {
  //   setcheckLoadingBtn(true);

  //   if (saveDataRecord) {
  //     changeStatusPolicyApi(saveDataRecord?.code, {
  //       status: saveDataRecord && saveDataRecord?.status == 'NOT_YET_COLLECTED_MONEY' ? 'COLLECTED_MONEY' : 'NOT_YET_COLLECTED_MONEY',
  //     })
  //       .then((res) => {
  //         if (res?.error) {
  //           notificationController.error({ message: `Change failed` });
  //           setopenModalConfirm(false);
  //           setcheckLoadingBtn(false);
  //         } else {
  //           notificationController.success({ message: `Change success` });
  //           callApiListPolicy();
  //           setopenModalConfirm(false);
  //           setcheckLoadingBtn(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         notificationController.error({ message: `Something Went wrong api ` });
  //         setopenModalConfirm(false);
  //         setcheckLoadingBtn(false);
  //       });
  //   }
  // }

  const handleOpenModalEdit = (value: DataTablePolicy) => {
    if (value) {
      setsaveDataRecord(value);
    }
    dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalEdit: true }));
  };

  const handleOpenModalDetail = (value: DataTablePolicy) => {
    if (value) {
      setsaveDataRecord(value);
    }
    dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalDetail: true }));
  };

  useEffect(() => {
    callApiListPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statePolicy?.filtersPolicy]);

  const columnsConfig: ColumnsType<DataTablePolicy> = [
    {
      width: '200px',
      title: 'Mã chính sách',
      key: 'code',
      dataIndex: 'code',
      fixed: 'left',

      render: (value: string) => <a className="text-loancode">{value ? value : '-'}</a>,
    },
    {
      width: '200px',
      title: 'Tên chính sách',
      key: 'name',
      dataIndex: 'name',
      render: (value: string) => <div className="text-loancode">{value ? value : '-'}</div>,
    },
    {
      width: '110px',
      title: 'Tỉ lệ cho vay / ĐH',
      key: 'maximumLoanRate',
      align: 'right',

      dataIndex: 'maximumLoanRate',
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '160px',
      title: 'Số tiền cho vay tối đa / KV',
      key: 'maximumLoanAmount',
      align: 'right',

      dataIndex: 'maximumLoanAmount',
      render: (value: number) => <div className="text-loancode">{value ? formatNumberWithCommas(value ? value : 0) : ''}</div>,
    },
    {
      width: '160px',
      title: 'Điểm tín dụng tối thiểu/ĐH',
      key: 'creditScore',
      align: 'right',

      dataIndex: 'creditScore',
      render: (value: string) => <div className="text-loancode">{value ? value : 0}</div>,
    },
    {
      width: '160px',
      title: 'Số tiền duyệt tự động',
      key: 'loanAmountAutoApprove',
      align: 'right',

      dataIndex: 'loanAmountAutoApprove',
      render: (value: number) => <div className="text-loancode">{value ? '≤ ' + formatNumberWithCommas(value ? value : 0) : 0}</div>,
    },
    {
      width: '100px',
      title: 'Lãi/ năm',
      key: 'interestRate',
      align: 'right',

      dataIndex: 'interestRate',
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '120px',
      title: 'Phí',
      align: 'right',
      key: 'feeRate',
      dataIndex: 'feeRate',
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '130px',
      title: 'Ngày ân hạn',
      key: 'gracePeriod',
      dataIndex: 'gracePeriod',
      render: (value: string) => <div className="text-loancode">{value ? value + ' Ngày' : 0}</div>,
    },
    {
      width: '140px',
      title: 'Số ngày/ kỳ',
      key: 'dayPerPeriod',
      dataIndex: 'dayPerPeriod',
      render: (value: string) => <div className="text-loancode">{value ? value + ' Ngày' : 0}</div>,
    },
    {
      width: '120px',
      title: 'Phí quá hạn kỳ 1',
      key: 'lateFeeFirstTerm',
      align: 'right',

      dataIndex: 'lateFeeFirstTerm',
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '140px',
      title: 'Phí tăng sau mỗi kỳ (%)',
      align: 'right',

      key: 'increasingFeePerPeriod',
      dataIndex: 'increasingFeePerPeriod',
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '120px',
      title: 'Số ngày bị thu hồi',
      key: 'dayRecall',
      dataIndex: 'dayRecall',
      render: (value: string) => <div className="text-loancode">{value ? value + ' Ngày' : 0}</div>,
    },
    {
      width: '120px',
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      render: (status: StatusPolicy) => <div className="text-loancode">{status == 'ACTIVE' ? 'Hoạt động' : '' || status == 'INACTIVE' ? 'Ngưng hoạt động' : ''}</div>,
    },
    {
      width: '134px',
      title: 'Hành động',
      render: (value: DataTablePolicy) => {
        return (
          <div className="text-watch">
            <span onClick={() => handleOpenModalDetail(value)}>Chi tiết</span>
            <span style={{ marginLeft: '10px' }} onClick={() => handleOpenModalEdit(value)}>
              Sửa
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content-table">
          <FormSearchPolicy></FormSearchPolicy>
          <Table
            scroll={{ x: 1900 }}
            rowSelection={{ type: 'checkbox' }}
            dataSource={dataPolicy && dataPolicy?.data}
            columns={columnsConfig}
            loading={checkLoading}
            sticky={true}
            rowKey="code"
            pagination={{
              current: dataPolicy?.pageIndex,
              pageSize: dataPolicy?.pageSize,
              total: dataPolicy?.total,
              onChange: (page: number, pageSize: number) => {
                {
                  dispatchPolicy(updateFiltersPolicy({ ...statePolicy?.filtersPolicy, pageIndex: page, pageSize: pageSize }));
                }
              },
            }}
          />
        </div>

        <ModalCreatePolicy></ModalCreatePolicy>

        <ModalDetailPolicy dataPolicy={saveDataRecord && saveDataRecord}></ModalDetailPolicy>

        <ModalEditPolicy dataPolicy={saveDataRecord && saveDataRecord}></ModalEditPolicy>
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
