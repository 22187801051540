import React, { useContext, useEffect } from 'react';
import * as S from '../Policy.style';
import { PolicyContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsPolicy } from '../Action';
import { Form } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { DataTablePolicy } from '../interface';
import { formatNumberWithCommas } from '@app/utils/utils';

type Props = {
  dataPolicy?: DataTablePolicy;
};

export default function ModalDetailPolicy(props: Props) {
  const { statePolicy, dispatchPolicy } = useContext(PolicyContext);
  const [form] = Form.useForm();

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  function handleCancel() {
    dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalDetail: false }));
  }

  useEffect(() => {
    if (props?.dataPolicy) {
      form.setFieldsValue({
        name: props?.dataPolicy?.name,
        maximumLoanRate: props?.dataPolicy?.maximumLoanRate,
        maximumLoanAmount: props?.dataPolicy?.maximumLoanAmount,
        loanAmountAutoApprove: props?.dataPolicy?.loanAmountAutoApprove,
        feeRate: props?.dataPolicy?.feeRate,
        interestRate: props?.dataPolicy?.interestRate,
        gracePeriod: props?.dataPolicy?.gracePeriod,
        dayPerPeriod: props?.dataPolicy?.dayPerPeriod,
        lateFeeFirstTerm: props?.dataPolicy?.lateFeeFirstTerm,
        increasingFeePerPeriod: props?.dataPolicy?.increasingFeePerPeriod,
        dayRecall: props?.dataPolicy?.dayRecall,
        creditScore: props?.dataPolicy?.creditScore,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataPolicy]);

  return (
    <S.ModalEditPolicy title={'Chi tiết chính sách'} open={statePolicy?.modalsPolicy?.modalDetail && statePolicy?.modalsPolicy?.modalDetail} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <div>
            <div className="content-header">
              <div className="item-form">
                <div className="label">Tên chính sách</div>
                <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                  {props?.dataPolicy?.name ? props?.dataPolicy?.name : '-'}
                </div>
              </div>
            </div>
            <h2 className="title-modal">Cài đặt cấu hình chung</h2>
            <div className="content-body">
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỉ lệ cho vay trên đơn hàng</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.maximumLoanRate ? props?.dataPolicy?.maximumLoanRate + ' %' : 0}
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Ngày ân hạn</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.gracePeriod ? props?.dataPolicy?.gracePeriod + ' Ngày' : 0}
                  </div>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Số tiền cho vay tối đa trên khoản vay</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.maximumLoanAmount ? formatNumberWithCommas(props?.dataPolicy?.maximumLoanAmount) + ' đ' : 0}
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày/ kỳ</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.dayPerPeriod ? props?.dataPolicy?.dayPerPeriod + ' Ngày' : 0}
                  </div>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Số tiền duyệt tự động</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.loanAmountAutoApprove ? formatNumberWithCommas(props?.dataPolicy?.loanAmountAutoApprove) + ' đ' : 0}
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Phí quá hạn kỳ 1</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.lateFeeFirstTerm ? props?.dataPolicy?.lateFeeFirstTerm + ' %' : 0}
                  </div>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Điểm tín dụng tối thiểu của đơn hàng</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.creditScore ? props?.dataPolicy?.creditScore + ' điểm' : 0}
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Phí tăng sau mỗi kỳ</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.increasingFeePerPeriod ? formatNumberWithCommas(props?.dataPolicy?.increasingFeePerPeriod) + ' %' : 0}
                  </div>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Lãi/ năm</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.interestRate ? props?.dataPolicy?.interestRate + ' %' : 0}
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày bị thu hồi</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.dayRecall ? props?.dataPolicy?.dayRecall + ' Ngày' : 0}
                  </div>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Phí</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {props?.dataPolicy?.feeRate ? props?.dataPolicy?.feeRate + ' %' : 0}
                  </div>
                </div>
              </div>
            </div>

            <FooterModal handleClickCancel={handleCancel} type={undefined} padding="24px 0px 0px 0px" labelCancel="Đóng"></FooterModal>
          </div>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalEditPolicy>
  );
}
