import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { ColumnsType } from 'antd/lib/table';
import { DataTableDetailPolicyAndPolices, DataTablePolicesType } from '../../interface';
import {} from '@app/controllers/queryStringParams';
import { updateModalsPartner } from '../../Action';
import { PartnerContext } from '../../Context';
import { ModalCreatePartner } from '../../Partner.style';
import { getListTablePolicyDefault } from '../../Partner.api';
import { Form, Input } from 'antd';
import * as S2 from '../../Partner.style';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import * as S from '../../Partner.style';
import ModalEditPolicyDefault from '../../Component/ModalEditPolicyDefault';
import { useParams } from 'react-router-dom';
import { formatNumberWithCommas } from '@app/utils/utils';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import ModalCreateCampaign from '../../Component/ModalCreateCampaign';
import ModalDetailCampaign from '../../Component/ModalDetailCampaign';
import ModalEditCampaign from '../../Component/ModalEditCampaign';

export default function TableDetailPartnerProduct() {
  const [checkLoading, setcheckLoading] = useState(false);
  const [data, setData] = useState<DataTableDetailPolicyAndPolices>();
  const [saveRecordDataPolices, setsaveRecordDataPolices] = useState<DataTablePolicesType>();
  const [saveRecordDataPolicesEdit, setsaveRecordDataPolicesEdit] = useState<DataTablePolicesType>();

  const { statePartner, dispatchPartner } = useContext(PartnerContext);
  const { uuid } = useParams();
  const { productName } = useParams();
  const [form] = Form.useForm();

  const callApiPolicyDetail = async () => {
    setcheckLoading(true);
    if (uuid && productName) {
      await getListTablePolicyDefault(uuid, productName)
        .then((res) => {
          setData(res);
          setcheckLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setData(undefined);
          setcheckLoading(false);
        });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMoveDetail = (value: DataTablePolicesType) => {
    value && setsaveRecordDataPolices(value);
    dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, ModalDetailCampaign: true }));
  };

  const handleOpenModalEdit = (value: DataTablePolicesType) => {
    value && setsaveRecordDataPolicesEdit(value);
    dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, ModalEditCampaign: true }));
  };

  useEffect(() => {
    callApiPolicyDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, productName]);

  const columnsConfigPolicyDefault: ColumnsType<any> = [
    {
      title: 'Tỉ lệ cho vay / ĐH',
      width: '100px',
      key: 'maximumLoanRate',
      dataIndex: 'maximumLoanRate',
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      title: 'Số tiền cho vay tối đa / KV',
      key: 'maximumLoanAmount',
      width: '150px',

      dataIndex: 'maximumLoanAmount',
      render: (value: number) => <div className="text-loancode">{value ? formatNumberWithCommas(value ? value : 0) : 0}</div>,
    },
    {
      title: 'Điểm tín dụng tối thiểu/ĐH',
      key: 'creditScore',
      width: '150px',

      dataIndex: 'creditScore',
      render: (value: string) => <div className="text-loancode">{value ? value : 0}</div>,
    },
    {
      title: 'Số tiền duyệt tự động',
      width: '150px',

      key: 'loanAmountAutoApprove',
      dataIndex: 'loanAmountAutoApprove',
      render: (value: number) => <div className="text-loancode">{value ? formatNumberWithCommas(value ? value : 0) : 0}</div>,
    },
    {
      width: '90px',
      title: 'Lãi/ năm',
      key: 'interestRate',
      dataIndex: 'interestRate',
      render: (value: number) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '130px',
      title: 'Phí',
      key: 'feeRate',
      dataIndex: 'feeRate',
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '130px',
      title: 'Ngày ân hạn',
      key: 'gracePeriod',
      dataIndex: 'gracePeriod',
      render: (value: string) => <div className="text-loancode">{value ? value + 'Ngày' : 0}</div>,
    },
    {
      width: '130px',
      title: 'Số ngày/ kỳ',
      key: 'dayPerPeriod',
      dataIndex: 'dayPerPeriod',
      render: (value: string) => <div className="text-loancode">{value ? value + 'Ngày' : 0}</div>,
    },
    {
      width: '130px',
      title: 'Phí quá hạn kỳ 1',
      key: 'lateFeeFirstTerm',
      dataIndex: 'lateFeeFirstTerm',
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '150px',
      title: 'Phí tăng sau mỗi kỳ (%)',
      key: 'increasingFeePerPeriod',
      dataIndex: 'increasingFeePerPeriod',
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '130px',
      title: 'Số ngày bị thu hồi',
      key: 'dayRecall',
      dataIndex: 'dayRecall',
      render: (value: string) => <div className="text-loancode">{value ? value + 'Ngày' : 0}</div>,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const columnsConfigPoliciesDefault: ColumnsType<DataTablePolicesType> = [
    {
      title: 'Tên chiến dịch',
      key: 'campaignName',
      fixed: 'left',

      dataIndex: 'campaignName',
      render: (value: string) => <a className="text-loancode">{value ? value : '-'}</a>,
    },
    {
      title: 'Mã chính sách',
      key: 'policyCode',
      fixed: 'left',

      dataIndex: 'policyCode',
      render: (value: string) => <a className="text-loancode">{value ? value : '-'}</a>,
    },
    {
      title: 'Thời gian áp dụng',
      key: 'time',
      render: (value: any) => <div className="text-loancode">{value?.beginDate && value?.endDate ? `${value?.beginDate} -> ${value?.endDate}` : '-'}</div>,
    },
    {
      title: 'Trạng thái',
      key: 'type',
      render: (value: DataTablePolicesType) => <StatusesText context="POLICES" status={value && value?.type}></StatusesText>,
    },
    {
      width: '150px',
      title: 'Tỉ lệ cho vay / ĐH',
      key: 'maximumLoanRate',
      dataIndex: 'maximumLoanRate',
      render: (value: string) => <div className="text-loancode">{value ? value + ' %' : 0}</div>,
    },
    {
      width: '150px',
      title: 'Điểm tín dụng tối thiểu/ĐH',
      key: 'creditScore',
      dataIndex: 'creditScore',
      render: (value: string) => <div className="text-loancode">{value ? value : 0}</div>,
    },
    {
      width: '150px',
      title: 'Số tiền duyệt tự động',
      key: 'loanAmountAutoApprove',
      dataIndex: 'loanAmountAutoApprove',
      render: (value: number) => <div className="text-loancode">{value ? formatNumberWithCommas(value ? value : 0) : 0}</div>,
    },
    {
      width: '150px',
      title: 'Lãi/ năm',
      key: 'interestRate',
      dataIndex: 'interestRate',
      render: (value: string) => <div className="text-loancode">{value ? value + ' %' : 0}</div>,
    },
    {
      width: '150px',
      title: 'Phí',
      key: 'feeRate',
      dataIndex: 'feeRate',
      render: (value: string) => <div className="text-loancode">{value ? value + ' %' : 0}</div>,
    },

    {
      width: '134px',
      title: 'Hành động',
      render: (value: DataTablePolicesType) => {
        return (
          <div className="text-watch">
            <span onClick={() => handleMoveDetail(value)}>Chi tiết</span>
            <span onClick={() => handleOpenModalEdit(value)} style={{ marginLeft: '10px' }}>
              Sửa
            </span>
          </div>
        );
      },
    },
  ];

  // function onChangeSearch(e: any) {
  //   if (e.key == 'Enter') {
  //     dispatchPartner(updateFiltersPartner({ ...statePartner?.filtersPartner, name: e.target.value, pageIndex: 1 }));
  //   }
  // }

  function openModalEditPolicyDefault() {
    dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalEditPolicyDefault: true }));
  }

  function openModalCreateCampaign() {
    dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, ModalCreateCampaign: true }));
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        code: data?.policyDefault.code && data?.policyDefault.code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <GS.WrapperOverrideInput>
      {/* <PartnerProvider> */}
      <Form form={form}>
        <GS.ContainerTable>
          <S.StyleDetailPartnerProduct className="box-content-table">
            <div className="box-formsearch">
              <div className="title">
                <div className="box-title">Chính sách mặc định</div>
                <div className="sub-title">Chính sách mặc định</div>
              </div>

              <S2.StyleSectionFormDetail>
                <div className="box-start">
                  <div className="box-input-code">
                    <Form.Item name={'code'} label="code" rules={[{ required: true, type: 'string' }]}>
                      <Input disabled defaultValue={data && data?.policyDefault.code} size={'small'} className={'style-input-create'} />
                    </Form.Item>
                  </div>
                </div>
                <div className="box-flex">
                  <BtnCreateStyle onClick={openModalEditPolicyDefault} label="Sửa chính sách mặc định"></BtnCreateStyle>
                </div>
              </S2.StyleSectionFormDetail>
            </div>
            <Table
              scroll={{ x: 1800 }}
              rowSelection={{ type: 'checkbox' }}
              dataSource={[data?.policyDefault ? data?.policyDefault : undefined]}
              columns={columnsConfigPolicyDefault}
              loading={checkLoading}
              sticky={true}
              rowKey="id"
            />
          </S.StyleDetailPartnerProduct>

          <S.StyleDetailPartnerProduct className="box-content-table">
            <div className="box-formsearch">
              <S2.StyleSectionFormDetail>
                <div className="box-start">Các chiến dịch</div>
                <div className="box-flex">
                  <BtnCreateStyle label="Thêm chiến dịch" onClick={openModalCreateCampaign}></BtnCreateStyle>
                </div>
              </S2.StyleSectionFormDetail>
            </div>

            <Table
              style={{ marginTop: '30px' }}
              scroll={{ x: 1800 }}
              rowSelection={{ type: 'checkbox' }}
              dataSource={data?.policies ? data?.policies : undefined}
              columns={columnsConfigPoliciesDefault}
              loading={checkLoading}
              sticky={true}
              rowKey="id"
            />
          </S.StyleDetailPartnerProduct>
          <ModalCreatePartner></ModalCreatePartner>
          <ModalCreateCampaign></ModalCreateCampaign>
          {saveRecordDataPolices ? <ModalDetailCampaign saveDataRecord={saveRecordDataPolices}></ModalDetailCampaign> : null}
          {saveRecordDataPolicesEdit ? <ModalEditCampaign saveDataRecord={saveRecordDataPolicesEdit}></ModalEditCampaign> : null}

          <ModalEditPolicyDefault codeDefault={data?.policyDefault.code}></ModalEditPolicyDefault>
        </GS.ContainerTable>
      </Form>
      {/* </PartnerProvider> */}
    </GS.WrapperOverrideInput>
  );
}
