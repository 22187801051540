import { Dispatch, useReducer } from 'react';
import {
  UPDATE_DATA_LOANLIST,
  UPDATE_FILTERS_DETAIL_WAIT_FOR_DISBURSEMENT,
  UPDATE_FILTERS_LOANLIST,
  UPDATE_FILTERS_WAIT_FOR_DISBURSEMENT,
  UPDATE_FORM,
  UPDATE_STATE_MODAL_DISBURSEMENT,
} from './Action';
import { ReducerType } from './interface';

export const initLoanList = {
  stateLoanList: {
    form: null,
    filters: {
      status: '',
      pageSize: 20,
      pageIndex: 1,
      partner: '',
      keyFilter: '',
      approvalFromDate: '',
      approvalToDate: '',
    },
    filtersWaitForDisbursement: {
      pageSize: 10,
      pageIndex: 1,
      reviewFromDate: '',
      reviewToDate: '',
    },
    filtersDetailWaitForDisbursement: {
      pageSize: 10,
      pageIndex: 1,
      partner: '',
    },
    stateModal: {
      stateModalDisbursement: false,
      stateModalDebt: false,
      stateModalUpdateLoan: false,
      openModalDetailImage: false,
      stateModalConfirm: false,
      openModalDetailPolicy: false,
      openModalAddImage: false,
    },
    data: {
      customer: {
        collection: [],
        meta: {
          pageSize: 1,
          current: 1,
          total: 0,
        },
      },
    },
  },
  dispatchLoanList: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        form: action.form,
      };
    case UPDATE_DATA_LOANLIST:
      return {
        ...state,
        data: {
          ...state.data,
          customer: action.dataLoanList,
        },
      };
    case UPDATE_FILTERS_LOANLIST:
      return {
        ...state,
        filters: action.filtersLoanList,
      };
    case UPDATE_FILTERS_WAIT_FOR_DISBURSEMENT:
      return {
        ...state,
        filtersWaitForDisbursement: action.filtersWaitForDisbursement,
      };
    case UPDATE_FILTERS_DETAIL_WAIT_FOR_DISBURSEMENT:
      return {
        ...state,
        filtersDetailWaitForDisbursement: action.filtersDetailWaitForDisbursement,
      };
    case UPDATE_STATE_MODAL_DISBURSEMENT:
      return {
        ...state,
        stateModal: action.stateModal,
      };

    default:
      return {
        ...state,
      };
  }
};

export const useLoanListReducer = () => {
  return useReducer(reducer, initLoanList.stateLoanList);
};
