import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Loan.styles';
import { LoanListContext } from '../Context';
import { DeleteOutlined } from '@ant-design/icons';
import * as GS from '@app/styles/GlobalStyle';
import { Modal, Tooltip, UploadFile, message } from 'antd';
import { updateStateModalDisbursement } from '../Action';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';
import Upload, { RcFile, UploadProps } from 'antd/lib/upload';
import { UploadImgApi, getImg } from '@app/api/UploadFIle.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { DataLoanDetailType } from '../interface';
import { createimageEvidenceApi } from '../Loan.api';
import { notificationController } from '@app/controllers/notificationController';

type Props = {
  data?: DataLoanDetailType | undefined;
  callApiDetailLoan: () => void;
};

export default function ModalDetailImageMoneyBorrow(props: Props) {
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const [loading, setloading] = useState(false);
  const [checkDelete, setcheckDelete] = useState(false);
  const [saveImg, setsaveImg] = useState();
  const [saveStringImg, setsaveStringImg] = useState<string | undefined>(props?.data?.loanEvidence && props?.data?.loanEvidence);

  function handleDelteImg() {
    setcheckDelete(true);
  }

  function handleCancelModalCreate() {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, openModalDetailImage: false }));
  }

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };
  function handleCancel() {
    setPreviewOpen(false);
  }

  const { Dragger } = Upload;
  const propsUpdate: UploadProps = {
    onPreview: handlePreview,
    name: 'file',
    multiple: true,
    maxCount: 1,
    listType: 'picture',
    // defaultFileList={[...fileList]},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status == 'removed') {
        setsaveStringImg('');
      }
      if (status !== 'uploading' && status !== 'removed') {
        const formData = new FormData();
        console.log('info.file', info.file);

        formData.append('file', info.file);

        UploadImgApi(
          formData,
          queryStringParams({
            packageType: 'TRANSACTION',
          }),
        )
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} tải lên thành công`);
              if (res) {
                setsaveStringImg(res?.key);
              }
            } else {
              // message.error(`${info.file.name} file  deleted `);
              setsaveStringImg('');
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };

  const callApiGetImg = async () => {
    if (props?.data && props?.data?.loanEvidence) {
      await getImg(props?.data?.loanEvidence)
        .then((res) => {
          setsaveImg(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    callApiGetImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data]);

  function onFinish() {
    if (saveStringImg && saveStringImg !== '' && props?.data) {
      // createimageEvidenceApi();
      setloading(true);
      createimageEvidenceApi(props?.data?.code, {
        evidence: saveStringImg,
      })
        .then((res) => {
          setloading(false);

          dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, openModalDetailImage: false }));

          if (res?.error) {
          } else {
            notificationController.success({ message: `Thêm thành công` });
            props?.callApiDetailLoan();
            setcheckDelete(false);
          }
        })
        .catch(() => {
          setloading(false);

          dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, openModalDetailImage: false }));

          console.log('err');
        });
    }
  }
  return (
    <GS.WrapperOverrideInput style={{ width: '100%', display: 'flex' }}>
      <S.ModalDetailImage footer={null} title="Chi tiết ảnh xác nhận ứng vốn" onCancel={handleCancelModalCreate} open={stateLoanList?.stateModal?.openModalDetailImage}>
        <div className="content-body">
          <div className="section-title">
            <Tooltip placement="top" title={<span>{props?.data?.loanEvidence ? props?.data?.loanEvidence : ''}</span>}>
              {checkDelete ? null : <div className="name-img value value-yellow">{props?.data?.loanEvidence ? props?.data?.loanEvidence : null}</div>}
            </Tooltip>
            <div>{checkDelete ? null : <DeleteOutlined onClick={handleDelteImg} />}</div>
          </div>
          {checkDelete ? (
            <div className="box-content-img">
              <div className="save-image">
                <Dragger {...propsUpdate}>
                  <p className="ant-upload-drag-icon">
                    <IconUploadFile />
                  </p>
                  <p className="ant-upload-text">
                    Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                  </p>
                </Dragger>
              </div>
            </div>
          ) : (
            <div className="box-content-img">
              <div className="box-img">
                <img
                  style={{ width: '100%' }}
                  src={
                    saveImg && saveImg
                      ? saveImg
                      : 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Floading-screen&psig=AOvVaw0A7H7rBe68YOeNx2IeudOj&ust=1677059194591000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCJD27sGqpv0CFQAAAAAdAAAAABAI'
                  }
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
        <div className="section-footer" style={{ marginTop: `${saveStringImg ? '75px' : '0px'}` }}>
          <FooterModal type={'submit'} handleLoading={loading} handleClickSave={onFinish} handleClickCancel={handleCancelModalCreate} labelCancel={'Hủy'} LabelSave={'Xác nhận'}></FooterModal>
        </div>
        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </S.ModalDetailImage>
    </GS.WrapperOverrideInput>
  );
}
