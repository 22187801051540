import styled from 'styled-components';
export const BoxRefund = styled.div`
  .text-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #597ef7;
    cursor: pointer;
  }
`;
export const FormSearch = styled.div`
  .ant-select {
    min-width: 197px !important;
  }
  margin-left: 3px;
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    width: 340px;
    height: 32px;
  }
  .ant-picker-panel-container {
    font-size: 13px !important;
  }
  .ant-select-selector {
    width: 247px;
  }
  .ant-picker {
    height: 32px;
    border-radius: 4px;
  }
  justify-content: start;
  .ant-picker-datetime-panel {
    font-size: 13px;
  }
  .box-formsearch {
    display: flex;
    justify-content: start;
  }
`;
