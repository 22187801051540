import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Policy.style';
import { PolicyContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsPolicy } from '../Action';
import { Form, Input } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { EditPolicyApi } from '../Policy.api';
import { notificationController } from '@app/controllers/notificationController';
import { DataTablePolicy } from '../interface';

type Props = {
  dataPolicy?: DataTablePolicy;
};

export default function ModalEditPolicy(props: Props) {
  const { statePolicy, dispatchPolicy } = useContext(PolicyContext);
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  function handleCancel() {
    dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalEdit: false }));
  }

  const onFinish = async (values: any) => {
    setloading(true);
    if (values && props?.dataPolicy) {
      EditPolicyApi(props?.dataPolicy?.code, {
        name: values?.name ? values?.name : props?.dataPolicy?.name,
        maximumLoanRate: values?.maximumLoanRate ? values?.maximumLoanRate : props?.dataPolicy?.maximumLoanRate,
        maximumLoanAmount: values?.maximumLoanAmount ? values?.maximumLoanAmount : props?.dataPolicy?.maximumLoanAmount,
        loanAmountAutoApprove: values?.loanAmountAutoApprove ? values?.loanAmountAutoApprove : props?.dataPolicy?.loanAmountAutoApprove,
        feeRate: values?.feeRate ? values?.feeRate : props?.dataPolicy?.feeRate,
        interestRate: values?.interestRate ? values?.interestRate : props?.dataPolicy?.interestRate,
        gracePeriod: values?.gracePeriod ? values?.gracePeriod : props?.dataPolicy?.gracePeriod,
        dayPerPeriod: values?.dayPerPeriod ? values?.dayPerPeriod : props?.dataPolicy?.dayPerPeriod,
        lateFeeFirstTerm: values?.lateFeeFirstTerm ? values?.lateFeeFirstTerm : props?.dataPolicy?.lateFeeFirstTerm,
        increasingFeePerPeriod: values?.increasingFeePerPeriod ? values?.increasingFeePerPeriod : props?.dataPolicy?.increasingFeePerPeriod,
        dayRecall: values?.dayRecall ? values?.dayRecall : props?.dataPolicy?.dayRecall,
        creditScore: values?.creditScore ? values?.creditScore : props?.dataPolicy?.creditScore,
      })
        .then((res) => {
          dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalEdit: false }));
          if (res?.error) {
            setloading(false);
          } else {
            setloading(false);
            notificationController.success({ message: `Sửa thành công` });
            // form.setFieldsValue({
            //   name: '',
            //   maximumLoanRate: '',
            //   maximumLoanAmount: '',
            //   loanAmountAutoApprove: '',
            //   feeRate: '',
            //   interestRate: '',
            //   gracePeriod: '',
            //   dayPerPeriod: '',
            //   lateFeeFirstTerm: '',
            //   increasingFeePerPeriod: '',
            //   dayRecall: '',
            //   creditScore: '',
            // });
          }
        })
        .catch(() => {
          notificationController.error({ message: `Something went wrong  Api` });
          setloading(false);

          dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalEdit: false }));
        });
    }
  };

  useEffect(() => {
    if (props?.dataPolicy) {
      form.setFieldsValue({
        name: props?.dataPolicy?.name,
        maximumLoanRate: props?.dataPolicy?.maximumLoanRate,
        maximumLoanAmount: props?.dataPolicy?.maximumLoanAmount,
        loanAmountAutoApprove: props?.dataPolicy?.loanAmountAutoApprove,
        feeRate: props?.dataPolicy?.feeRate,
        interestRate: props?.dataPolicy?.interestRate,
        gracePeriod: props?.dataPolicy?.gracePeriod,
        dayPerPeriod: props?.dataPolicy?.dayPerPeriod,
        lateFeeFirstTerm: props?.dataPolicy?.lateFeeFirstTerm,
        increasingFeePerPeriod: props?.dataPolicy?.increasingFeePerPeriod,
        dayRecall: props?.dataPolicy?.dayRecall,
        creditScore: props?.dataPolicy?.creditScore,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataPolicy]);

  return (
    <S.ModalEditPolicy title={'Chi tiết chính sách'} open={statePolicy?.modalsPolicy?.modalEdit && statePolicy?.modalsPolicy?.modalEdit} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" onFinish={form && onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <div>
            <div className="content-header">
              <div className="item-form">
                <div className="label">Tên chính sách</div>
                <Form.Item name={'name'} label="Tên chính sách" rules={[{ required: true, type: 'string' }]}>
                  <Input type="string" size={'small'} className={'style-input-create'} />
                </Form.Item>
              </div>
            </div>
            <h2 className="title-modal">Cài đặt cấu hình chung</h2>

            <div className="content-body">
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỉ lệ cho vay trên đơn hàng</div>
                  <Form.Item name={'maximumLoanRate'} label="Tỉ lệ cho vay trên đơn hàng" rules={[{ required: true }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Ngày ân hạn</div>
                  <Form.Item name={'gracePeriod'} label="Ngày ân hạn" rules={[{ required: true }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Số tiền cho vay tối đa trên khoản vay</div>
                  <Form.Item name={'maximumLoanAmount'} label="Số tiền cho vay tối đa trên khoản vay" rules={[{ required: true }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày/ kỳ</div>
                  <Form.Item name={'dayPerPeriod'} label="Số ngày/ kỳ" rules={[{ required: true }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Số tiền duyệt tự động</div>
                  <Form.Item name={'loanAmountAutoApprove'} label="Số tiền duyệt tự động" rules={[{ required: true }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Phí quá hạn kỳ 1</div>
                  <Form.Item name={'lateFeeFirstTerm'} label="Phí quá hạn kỳ 1" rules={[{ required: true }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Điểm tín dụng tối thiểu của đơn hàng</div>
                  <Form.Item name={'creditScore'} label="Điểm tín dụng tối thiểu của đơn hàng" rules={[{ required: true }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Phí tăng sau mỗi kỳ</div>
                  <Form.Item name={'increasingFeePerPeriod'} label="Phí tăng sau mỗi kỳ" rules={[{ required: true }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Lãi/ năm</div>
                  <Form.Item name={'interestRate'} label="Lãi/ năm" rules={[{ required: true }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày bị thu hồi</div>
                  <Form.Item name={'dayRecall'} label="Số ngày bị thu hồi" rules={[{ required: true }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Phí</div>
                  <Form.Item name={'feeRate'} label="Phí" rules={[{ required: true }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <FooterModal handleClickCancel={handleCancel} handleLoading={loading} type="submit" padding="24px 0px 0px 0px" LabelSave="Lưu thay đổi" labelCancel="Huỷ"></FooterModal>
          </div>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalEditPolicy>
  );
}
