import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import {
  DataResponseApiPartnerList,
  DataResponseApiPartnerListProduct,
  DataTableDetailPolicyAndPolices,
  DataTablePartner,
  paramsApiCreateCampaign,
  paramsApiCreatePartner,
  paramsApiEditCampaign,
  paramsApiEditPartner,
  paramsUpdateStatusPartnerApiType,
  responseApiCreateCampaign,
  responseApiCreatePartner,
  responseApiEditCampaign,
} from './interface';
const httpApi = initAxios();
interface LoanRequestConfig extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfig: LoanRequestConfig = {
  secured: true,
};

// const TokenConfigTextPlain: CreateDebtConfig = {
//   secured: true,
//   ContentType: 'text/plain',
// };

// const httpApi = initAxios();
export const getPartnerApi = (params: string | undefined): Promise<DataResponseApiPartnerList> => httpApi.get(`/loans/v1/partners?${params}`, { ...TokenConfig });

export const getListTableProductInDetailPartner = (uuid: string, params: string | undefined): Promise<DataResponseApiPartnerListProduct> =>
  httpApi.get(`/loans/v1/partners/${uuid}/products?${params}`, { ...TokenConfig });

export const getListTablePolicyDefault = (uuid: string, productName: string): Promise<DataTableDetailPolicyAndPolices> =>
  httpApi.get(`/loans/v1/partners/${uuid}/products/${productName}`, { ...TokenConfig });

export const getDetailPartnerApi = (uuid: string): Promise<DataTablePartner> => httpApi.get(`/partners/v1/${uuid}`, { ...TokenConfig });

export const CreatePartnerApi = (params: paramsApiCreatePartner): Promise<responseApiCreatePartner> => httpApi.post(`/loans/v1/partners`, params, { ...TokenConfig });

export const EditPartnerApi = (code: string, params: paramsApiEditPartner): Promise<any> => httpApi.put(`loans/v1/policy/${code}`, params, { ...TokenConfig });

export const changeStatusPartnerApi = (code: string, params: paramsUpdateStatusPartnerApiType): Promise<any> => httpApi.put(`/loans/v1/Partners/${code}/status?`, params, { ...TokenConfig });

export const EditPolicyDefaultPartnerApi = (uuid: string | undefined, productName: string | undefined, params: any): Promise<any> =>
  httpApi.put(`/loans/v1/partners/${uuid}/products/${productName}/policy-default`, params, { ...TokenConfig });

export const CreateCampaignApi = (uuid: string, productName: string, params: paramsApiCreateCampaign): Promise<responseApiCreateCampaign> =>
  httpApi.post(`/loans/v1/partners/${uuid}/${productName}/campaigns`, params, { ...TokenConfig });

export const EditCampaignApi = (uuid: string, productName: string, idCampaign: number | string, params: paramsApiEditCampaign): Promise<responseApiEditCampaign> =>
  httpApi.put(`/loans/v1/partners/${uuid}/${productName}/campaigns/${idCampaign}`, params, { ...TokenConfig });
