import React, { useContext, useState } from 'react';
import * as S from '../Policy.style';
import { PolicyContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsPolicy } from '../Action';
import { Form, Input } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { CreatePolicyApi } from '../Policy.api';
import { notificationController } from '@app/controllers/notificationController';

type Props = {
  data?: any;
};

export default function ModalCreatePolicy({}: Props) {
  const { statePolicy, dispatchPolicy } = useContext(PolicyContext);
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  function handleCancel() {
    dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalCreate: false }));
  }

  const onFinish = async (values: any) => {
    setloading(true);
    if (values) {
      CreatePolicyApi({
        name: values?.name,
        code: values?.code,
        maximumLoanRate: values?.maximumLoanRate,
        maximumLoanAmount: values?.maximumLoanAmount,
        loanAmountAutoApprove: values?.loanAmountAutoApprove,
        feeRate: values?.feeRate,
        interestRate: values?.interestRate,
        gracePeriod: values?.gracePeriod,
        dayPerPeriod: values?.dayPerPeriod,
        lateFeeFirstTerm: values?.lateFeeFirstTerm,
        increasingFeePerPeriod: values?.increasingFeePerPeriod,
        dayRecall: values?.dayRecall,
        creditScore: values?.creditScore,
      })
        .then((res) => {
          dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalCreate: false }));

          if (res?.error) {
            setloading(false);
            form.setFieldsValue({
              name: '',
              code: '',
              maximumLoanRate: '',
              maximumLoanAmount: '',
              loanAmountAutoApprove: '',
              feeRate: '',
              interestRate: '',
              gracePeriod: '',
              dayPerPeriod: '',
              lateFeeFirstTerm: '',
              increasingFeePerPeriod: '',
              dayRecall: '',
              creditScore: '',
            });
          } else {
            setloading(false);
            notificationController.success({ message: `Tạo thành công` });
            form.setFieldsValue({
              name: '',
              code: '',
              maximumLoanRate: '',
              maximumLoanAmount: '',
              loanAmountAutoApprove: '',
              feeRate: '',
              interestRate: '',
              gracePeriod: '',
              dayPerPeriod: '',
              lateFeeFirstTerm: '',
              increasingFeePerPeriod: '',
              dayRecall: '',
              creditScore: '',
            });
          }
        })
        .catch(() => {
          notificationController.error({ message: `Something went wrong  Api` });
          setloading(false);
          form.setFieldsValue({
            name: '',
            code: '',
            maximumLoanRate: '',
            maximumLoanAmount: '',
            loanAmountAutoApprove: '',
            feeRate: '',
            interestRate: '',
            gracePeriod: '',
            dayPerPeriod: '',
            lateFeeFirstTerm: '',
            increasingFeePerPeriod: '',
            dayRecall: '',
            creditScore: '',
          });
          dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalCreate: false }));
        });
    }
  };
  return (
    <S.ModalCreatePolicy title={'Thêm chính sách'} open={statePolicy?.modalsPolicy?.modalCreate} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <div>
            <div className="content-header">
              <div className="item-form">
                <div className="label">Tên chính sách</div>
                <Form.Item name={'name'} label="Tên chính sách" rules={[{ required: true, type: 'string' }]}>
                  <Input type="string" size={'small'} className={'style-input-create'} />
                </Form.Item>
              </div>
              <div className="item-form">
                <div className="label">Mã chính sách</div>
                <Form.Item name={'code'} label="Mã chính sách" rules={[{ required: true, type: 'string' }]}>
                  <Input type="string" size={'small'} className={'style-input-create'} />
                </Form.Item>
              </div>
            </div>
            <h2 className="title-modal">Cài đặt cấu hình chung</h2>

            <div className="content-body">
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỉ lệ cho vay trên đơn hàng</div>
                  <Form.Item name={'maximumLoanRate'} label="Tỉ lệ cho vay trên đơn hàng" rules={[{ required: true, type: 'string' }]}>
                    <Input pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Ngày ân hạn</div>
                  <Form.Item name={'gracePeriod'} label="Ngày ân hạn" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Số tiền cho vay tối đa trên khoản vay</div>
                  <Form.Item name={'maximumLoanAmount'} label="Số tiền cho vay tối đa trên khoản vay" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày/ kỳ</div>
                  <Form.Item name={'dayPerPeriod'} label="Số ngày/ kỳ" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Số tiền duyệt tự động</div>
                  <Form.Item name={'loanAmountAutoApprove'} label="Số tiền duyệt tự động" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Phí quá hạn kỳ 1</div>
                  <Form.Item name={'lateFeeFirstTerm'} label="Phí quá hạn kỳ 1" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Điểm tín dụng tối thiểu của đơn hàng</div>
                  <Form.Item name={'creditScore'} label="Điểm tín dụng tối thiểu của đơn hàng" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Phí tăng sau mỗi kỳ</div>
                  <Form.Item name={'increasingFeePerPeriod'} label="Phí tăng sau mỗi kỳ" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Lãi/ năm</div>
                  <Form.Item name={'interestRate'} label="Lãi/ năm" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày bị thu hồi</div>
                  <Form.Item name={'dayRecall'} label="Số ngày bị thu hồi" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Phí</div>
                  <Form.Item name={'feeRate'} label="Phí" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <FooterModal handleClickCancel={handleCancel} handleLoading={loading} type="submit" padding="24px 0px 0px 0px" LabelSave="Lưu thay đổi" labelCancel="Huỷ"></FooterModal>
          </div>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalCreatePolicy>
  );
}
