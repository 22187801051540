import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import {
  BodyApiCalCuLateType,
  DataResponseListRequestLoanType,
  FilterParamsRequestID,
  ParamsBodyCreateLoanType,
  ResponseApiCalCuLateType,
  ResponseApprovalType,
  ResponseCreateLoanType,
  ResponseRejectType,
} from './interface';
const httpApi = initAxios();
interface LoanRequestConfig extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfig: LoanRequestConfig = {
  secured: true,
};

export const getRequestLoanManagementApi = (params: string): Promise<DataResponseListRequestLoanType> => httpApi.get(`/loans/v1/request?${params}`, { ...TokenConfig });
export const ApprovalRequestLoanManagementApi = (params: FilterParamsRequestID): Promise<ResponseApprovalType> =>
  httpApi.post(
    '/loans/v1/request/approval',
    {
      ...params,
    },
    { ...TokenConfig },
  );

export const RejectRequestLoanManagementApi = (params: FilterParamsRequestID): Promise<ResponseRejectType> =>
  httpApi.post(
    '/loans/v1/request/reject',
    {
      ...params,
    },
    {
      ...TokenConfig,
    },
  );

export const CreateLoanApi = (params: ParamsBodyCreateLoanType): Promise<ResponseCreateLoanType> =>
  httpApi.post(
    '/loans/v1/request/manual',
    {
      ...params,
    },
    {
      ...TokenConfig,
    },
  );

// export const GetLoanApi = (params: ParamsBodyCreateLoanType): Promise<ResponseCheckPhoneCreateLoanType> =>
//   httpApi.get(
//     '/loans/v1/request/manual',

//     {
//       ...TokenConfig,
//     },
//   );

export const CheckPhoneApi = (phone: string): Promise<any> =>
  httpApi.get(
    `/users/v1/check/phone?phone=${phone}`,

    {
      ...TokenConfig,
    },
  );

export const CalCuLateApi = (body: BodyApiCalCuLateType): Promise<ResponseApiCalCuLateType> =>
  httpApi.post(`/loans/v1/request/manual/calculate`, body, {
    ...TokenConfig,
  });
