import React, { useContext, useEffect, useState } from 'react';
// import { Form, Button } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../../Management/CustomerManagement.styles';
import * as S2 from '../Loan.styles';
import { DatePicker, DatePickerProps, Form, Input, Select } from 'antd';
import { AiOutlineExport, AiOutlineSearch } from 'react-icons/ai';
import { updateFiltersLoanList } from '../Action';
import { LoanListContext } from '../Context';
import { RangePickerProps } from 'antd/lib/date-picker';
import { DataResponseApiPartnerList } from '../../Partner/interface';
import { getPartnerApi } from '../../Partner/Partner.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { ExportExcelLoan } from '../Loan.api';
import { notificationController } from '@app/controllers/notificationController';
const { RangePicker } = DatePicker;

interface Props {
  checkSection?: boolean;
}
export const FormSearchLoan = (props: Props) => {
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const [checkLoading, setcheckLoading] = useState(false);

  function onChangeTime(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    if (dateString) {
      dispatchLoanList(updateFiltersLoanList({ ...stateLoanList?.filters, approvalFromDate: dateString[0], approvalToDate: dateString[1], pageIndex: 1 }));
    } else {
      dispatchLoanList(updateFiltersLoanList({ ...stateLoanList?.filters, approvalFromDate: '', approvalToDate: '', pageIndex: 1 }));
    }
  }

  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchLoanList(updateFiltersLoanList({ ...stateLoanList?.filters, keyFilter: e.target.value, pageIndex: 1 }));
    }
  }

  function onChangeSelectPartner(e: string) {
    dispatchLoanList(updateFiltersLoanList({ ...stateLoanList?.filters, partner: e, pageIndex: 1 }));
  }

  function onChangeSelectStatus(e: string) {
    dispatchLoanList(updateFiltersLoanList({ ...stateLoanList?.filters, status: e, pageIndex: 1 }));
  }

  const [dataPartner, setDataPartner] = useState<DataResponseApiPartnerList>();

  const callApiListPartner = async () => {
    await getPartnerApi(
      queryStringParams({
        name: '',
        pageIndex: 1,
        pageSize: 1000,
      }),
    )
      .then((res) => {
        setDataPartner(res);
      })
      .catch((err) => {
        console.log(err);
        setDataPartner(undefined);
      });
  };

  useEffect(() => {
    callApiListPartner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function ExportExcelFunc() {
    setcheckLoading(true);
    ExportExcelLoan(
      queryStringParams({
        status: stateLoanList?.filters.status,
        partner: stateLoanList?.filters.partner,
        keyFilter: stateLoanList?.filters.keyFilter,
        approvalFromDate: stateLoanList?.filters.approvalFromDate,
        approvalToDate: stateLoanList?.filters.approvalToDate,
      }),
    )
      .then((res) => {
        if (!res?.error) {
          setcheckLoading(false);
          notificationController.success({ message: `Xuất file thành công , vui lòng check mail` });
        }
      })
      .catch(() => {
        setcheckLoading(false);
        console.log('err');
      });
  }

  return (
    <GS.WrapperOverrideInput>
      <GS.FormSearch>
        <S.WrapperFilters>
          <S2.DflexStartEnd style={{ marginTop: props?.checkSection ? '0px' : '', marginLeft: props?.checkSection ? '-15px' : '' }}>
            <S2.Dflex>
              <Form.Item name={'keyword'}>
                <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'ID khoản vay, tên khách hàng, mã đơn hàng'} />
              </Form.Item>
              <div className={'filter-item'} style={{ marginLeft: '2px' }}>
                <Form.Item name={'statuses'}>
                  <RangePicker format="DD-MM-YYYY" onChange={onChangeTime} />
                </Form.Item>
              </div>
              <div className={'filter-item'} style={{ marginLeft: '2px', minWidth: '160px' }}>
                <Form.Item name={'statuses'}>
                  <Select onChange={onChangeSelectPartner} className="style-select-partner" placeholder={'Đối tác liên kết'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value={''}>Tất cả</Select.Option>

                    {/*<Select.Option value={'BaoGam'}>Báo Gấm</Select.Option>
                    <Select.Option value={'BaoGam'}>Hồng Hạc</Select.Option> */}
                    {dataPartner?.data &&
                      dataPartner?.data?.map((item, index) => (
                        <Select.Option key={index} value={`${item?.code}`}>
                          {item?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className={'filter-item'} style={{ marginLeft: '8px' }}>
                <Form.Item name={'statuses'}>
                  <Select onChange={onChangeSelectStatus} className="style-select-partner" placeholder={'Trạng thái'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value={''}>Tất cả</Select.Option>
                    <Select.Option value={'ACTIVE'}>Đang hoạt động</Select.Option>
                    <Select.Option value={'CANCELED'}>Đã hủy</Select.Option>
                    <Select.Option value={'DONE'}>Đã tất toán</Select.Option>
                    <Select.Option value={'RECALL'}>Thu hồi</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </S2.Dflex>
            {props?.checkSection ? (
              ''
            ) : (
              <div>
                <Form.Item>
                  <GS.StyleBtnExcel loading={checkLoading} onClick={ExportExcelFunc} icon={<AiOutlineExport />}>
                    Xuất Excel
                  </GS.StyleBtnExcel>
                </Form.Item>
              </div>
            )}
          </S2.DflexStartEnd>
        </S.WrapperFilters>
      </GS.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
