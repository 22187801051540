import { CaretRightOutlined, SwapRightOutlined } from '@ant-design/icons';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import * as S from '../Loan.styles';
import IconNotPayment from '@app/assets/icons/icon-not-payment.svg';

import { ReactComponent as IconUploadPDF } from '@app/assets/icons/icon-upload-pdf.svg';
import React, { useContext } from 'react';
import ModalCreateDisbursement from './ModalCreateDisbursement';
import { formatNumberWithCommas } from '@app/utils/utils';
import { DataLoanDetailType } from '../interface';
import { updateStateModalDisbursement } from '../Action';
import { LoanListContext } from '../Context';
import { Tooltip } from 'antd';
type Props = {
  dataLoanDetail: DataLoanDetailType | undefined;
};
export default function DisbursementSection(props: Props) {
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);

  function ShowmodalCreate() {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, stateModalDisbursement: true }));
  }
  function handlePDF() {
    window.location.replace(
      'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.istockphoto.com%2Fphotos%2Fhanoi&psig=AOvVaw2o9pkDKii1nZRcbVN-x4Sa&ust=1670678855407000&source=images&cd=vfe&ved=0CA8QjRxqFwoTCNi6nu_R7PsCFQAAAAAdAAAAABAE',
    );
  }

  return (
    <div className="section-disbursement">
      <div className="title-disbursement">Giải ngân</div>
      <div className="content">
        <div className="box-item-disbursement">
          {props.dataLoanDetail?.transactions && props.dataLoanDetail?.transactions.length > 0 ? (
            props.dataLoanDetail?.transactions?.map((item, index) => (
              <div className="line-transaction" key={index}>
                <CaretRightOutlined className="css-icon-caret" />
                <div className="box-item style-fund">
                  <div className="label">Chuyển từ</div>
                  <Tooltip placement="top" title={<span>{item?.fund ? item?.fund : ''}</span>}>
                    <div className="value">{item?.fund ? item?.fund : ''}</div>
                  </Tooltip>
                </div>
                <SwapRightOutlined className="css-icon-swap" />
                <div className="box-item style-partner">
                  <div className="label">Đến</div>
                  <Tooltip placement="top" title={<span>{item?.partner ? item?.partner : ''}</span>}>
                    <div className="value">{item?.partner ? item?.partner : ''}</div>
                  </Tooltip>
                </div>
                <div className="box-item amount-disbursement style-amount">
                  <div className="label">Số tiền giải ngân</div>
                  <Tooltip placement="top" title={<span>{item?.amount ? formatNumberWithCommas(item?.amount) : 0}</span>}>
                    <div className="value style-amount-disbursement">{item?.amount ? formatNumberWithCommas(item?.amount) : 0}</div>
                  </Tooltip>
                </div>
                <div className="box-item style-code">
                  <div className="label">Mã giải ngân</div>
                  <Tooltip placement="top" title={<span>{item?.code ? item?.code : ''}</span>}>
                    <div className="value value-yellow">{item?.code ? item?.code : ''}</div>
                  </Tooltip>
                </div>
                <div className="box-item style-transDate break-word-date">
                  <div className="label">Ngày giải ngân</div>
                  <Tooltip placement="top" title={<span>{item?.transDate ? item?.transDate.toLocaleString() : ''}</span>}>
                    <div className="value style-maxwidth-date">{item?.transDate ? item?.transDate.toLocaleString() : ''}</div>
                  </Tooltip>
                </div>
                <div className="box-item style-pdf">
                  <div className="label">Chứng từ</div>
                  <div className="value value-yellow" onClick={handlePDF}>
                    <IconUploadPDF style={{ marginRight: '5px' }}></IconUploadPDF>
                    File.pdf
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="box-not-payment">
              <div>
                <div className="box-img">
                  <img src={IconNotPayment} />
                </div>
                <div>Chưa có khoản giải ngân</div>
              </div>
            </div>
          )}
        </div>
        <S.DflexFlexEnd style={{ padding: '16px 24px 16px 24px', marginTop: '0px' }}>
          <BtnCreateStyle onClick={ShowmodalCreate} label={'Phiếu giải ngân'}></BtnCreateStyle>
        </S.DflexFlexEnd>
      </div>

      <ModalCreateDisbursement dataLoanDetail={props.dataLoanDetail}></ModalCreateDisbursement>
    </div>
  );
}
